<template>
<v-row justify="center">
  <v-dialog v-model="shower" transition="dialog-bottom-transition" fullscreen scrollable persistent>
    <v-card id="dialogpreview" class="ma-0 pa-0">
      <v-toolbar :color="color.theme" :dark="color.darkTheme" :text-color="color.chipText" class="pa-0 ma-0">
        <v-btn icon dark @click="closedialog(),$emit('openDrag')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title :color="color.theme">{{ filedata.file_name || filedata.filename  }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="downloadfile(filedata)">
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text style="overflow-y: auto; height: 90%" v-if="loading === true">
        <v-container>
          <v-layout row wrap justify-center align-center fill-height>
            <v-flex xs12 lg12 xl12 class="text-center">
              <div id="detailpreview">
                <v-progress-linear
                  v-if="loading === true"
                  background-color="light-blue lighten-4"
                  color="success"
                  indeterminate
                  rounded
                ></v-progress-linear> 
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-text v-else-if="compatible === true && compatibletype === 'image'" style="overflow-y: auto; height: 90%">
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 lg12 xl12 class="text-center ma-5 " >
                  <img
                    :src="filearraybuffer"
                    alt="preview"
                    style=" max-width: 100%;  height: auto;  max-height: 90%; "
                  />
            </v-flex>
          </v-layout>
      </v-card-text>
        <v-card-text v-else-if="compatible === false" style="overflow-y: auto; height: 90%">
         <v-container>
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 lg12 xl12 class="text-center">
                  <v-icon size="200" color="grey">mdi-file-cancel</v-icon>
                  <br />
                  <br />
                  <p style="font-size: 25px; color: grey">ไฟล์นี้ไม่รองรับในการดูตัวอย่าง</p>
            </v-flex>
          </v-layout>
         </v-container>
      </v-card-text>
      <v-card-text v-else-if="loading === false && compatibletype === 'pdf'"  style="overflow-y: auto; height: 93%" class="ma-0 pa-0">
        <div class="iframe-container" v-if="screenSize >=600">
         <iframe :src="rawbase64" allowfullscreen></iframe>
        </div>
        <div class="iframe-container-mobile" v-else-if="screenSize <600">
         <iframe :src="rawbase64"></iframe>
        </div>
          <div v-else class="pa-4 text-center">
            <vuePdf
              v-for="i in pageCount"
              :key="i"
              :src="filearraybuffer"
              :page="i"
              style="padding-bottom: 10px"
            ></vuePdf>
          </div>
      </v-card-text>
    </v-card>
  <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload=false"
      :percent="percent"
      :namedowload="namedowload"
  ></dialogsnackbermultidowload>
    <!-- snackbardowload -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowload = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{namedowload}}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
            >&nbsp; {{ percentCompleted }}</v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import vuePdf from "vue-pdf";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
// import vuePdf from 'pdfvuer'

const dialogsnackbermultidowload =() => import("../optional/dialog-snackbarmultidowmload");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  validations: {
    filename: { required }
  },
  props: ["show", "parentfolder", "filedata"],
  components: { vuePdf,dialogsnackbermultidowload },
  data: function() {
    return {
      opensnackbarmultidownload:false,
      percent:0,
      namedowload:'',
      timeout: 6000,
      percentCompleted: 0,
      snackbardowload:false,
      rawbase64: '',
      scale: 'page-width',
      compatibletype: '',
      compatible: false,
      pageCount: 0,
      loading: true,
      filearraybuffer: "",
      SizeImage:
        window.innerHeight > window.innerWidth
          ? window.innerWidth
          : window.innerHeight,
      filename: "",
      createprogress: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" }
      ],
      right: null,
      drawer: null,
      sizepdf: 100
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log(this.$router.app["_route"]["name"])
          if(this.$router.app["_route"]["name"] !== 'myinbox_sourcesystem'){
            let filename = this.filedata.file_name.split(".");
            let i;
            let namewithoutextension = "";
            for (i = 0; i < filename.length; i++) {
              if (i !== filename.length - 1) {
                namewithoutextension += filename[i];
              } else if (filename.length - 1 === 0) {
                namewithoutextension += filename[i];
              }
            }

            if (
              filename[filename.length - 1] === "png" ||
              filename[filename.length - 1] === "jpg" ||
              filename[filename.length - 1] === "jpeg"||
              filename[filename.length - 1] === "gif" ||
              filename[filename.length - 1] === "JPG" 
              // filename[filename.length - 1] === "CR2"
            ) {
              this.compatible = true;
              this.compatibletype = 'image'
              setTimeout(() => {
                this.getpreview();
              }, 1000);
            } else if (filename[filename.length - 1] in ["pdf","xlsx","docx","pptx"] ){
              this.compatible = true;
              this.compatibletype = 'pdf'
              setTimeout(() => {
                this.getpreviewpdf(filename[filename.length - 1]);
              }, 1000);
            
            } else {
              // this.loading = false;
              // this.compatible = false;
              this.compatible = true;
              this.compatibletype = 'pdf'
              setTimeout(() => {
                this.getpreviewpdf(filename[filename.length - 1]);
              }, 1000);
            
            }

          }else{
             if (
               this.filedata['data_type'] === "png" ||
               this.filedata['data_type'] === "jpg" ||
               this.filedata['data_type'] === "jpeg"||
               this.filedata['data_type'] === "gif" ||
               this.filedata['data_type'] === "JPG" 
              //  this.filedata['data_type'] === "CR2"
            ) {
              this.compatible = true;
              this.compatibletype = 'image'
              setTimeout(() => {
                this.getpreview();
              }, 1000);
            } else if ( this.filedata['data_type'] in ["pdf","xlsx","docx","pptx"]){
              this.compatible = true;
              this.compatibletype = 'pdf'
              setTimeout(() => {
                this.getpreviewpdf(this.filedata['data_type']);
              }, 1000);
            
            } else {
              this.loading = false;
              this.compatible = false;
            }
          }
          this.$emit('closeDrag')
          return this.show;
        } else {
          return this.show;
        }
      },
      set(value) {
        if (!value) {
          this.$emit("closenewpreviewfile");
        }
      }
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.filename.$dirty) return errors;
      !this.$v.filename.required && errors.push("กรุณากรอกชื่อ ไฟล์/โฟลเดอร์");
      return errors;
    },
    screenSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
  },
  methods: {
    async getpreview() {
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
        payload,
        {
          withCredentials: false,
          responseType: "arraybuffer",
          headers: { Authorization: auth.code },
        },
      );
      try {
        const buffer = Buffer.from(response.data, "base64");
        let u8 = new Uint8Array(buffer);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(buffer),
            function(p, c) {
              return p + String.fromCharCode(c);
            },
            ""
          )
        );
        let mimetype = "image/png";
        // console.log(b64encoded);

        this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded
        this.loading = false;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getpreviewpdf(type) {
      this.rawbase64 = ""
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id
      };
      // console.log(payload);
      var extensionfile = ['xlsx','pptx','xls','ppt','doc','docx'];
      var n = extensionfile.includes(type, 0);
       
      if(n === true){
        this.rawbase64 = 'https://view.officeapps.live.com/op/view.aspx?src='+ process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.dataUsername + '/'+ this.filedata.file_id
      }else{
        this.rawbase64 = 'https://docs.google.com/viewer?url='+ process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.dataUsername + '/'+ this.filedata.file_id + '&embedded=true'
        // this.rawbase64 = 'https://view.officeapps.live.com/op/view.aspx?src='+ process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.dataUsername + '/'+ this.filedata.file_id
      }
      this.loading = false;
    },

    zoomin(){
      this.sizepdf = this.sizepdf - 10
      document.getElementById("zoompdf").style.width = this.sizepdf + '%';

    },
    downloadfile(currentfile) {
      this.percent = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          type: "warning",
          title: "Coming Soon !!"
        });
     } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id
        };
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // this.snackbardowload = true;
        this.opensnackbarmultidownload = true;
        this.axios
          .get(url, {
            onDownloadProgress: progressEvent => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(this.percentCompleted);
              this.namedowload = currentfile.file_name;
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              }else{
                this.percentCompleted = progresspercent
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer"
          })
          .then(response => {
            if (response.statusText === "OK") {
              this.percentCompleted = 100;
            }
            // console.log("fff",response);
            this.namedowload = currentfile.file_name;
            const blob = new Blob([response.data]);
            const content = response.headers["content-type"];
            saveAs(blob, currentfile.file_name);
          })
          .catch(error => {
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
            });
            console.log(error);
          });
      }
    },

    previewimage(parameter) {},

    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
    },
    closedialog() {
      this.$emit("closenewpreviewfile");
      this.loading = true;
      this.filearraybuffer = "";
      this.compatibletype = '';
      this.compatible = false;
      this.pageCount= 0;
    }
  }
};
</script>

<style >
#detailpreview {
  padding-top: 50px;
  padding-bottom: 25px;
  height: auto;
  overflow-y: auto;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70;
  left: 0;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

#dialogpreview {
  background: rgba(0, 0, 0, 0.85);
}

#zoompdf{
  padding-bottom: 20px; width: 90%;
} 

/* .pdf_render {
  overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    overflow-scrolling: touch;
} */

#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

/* .iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  padding-bottom: 0;
  height: 80%;
  
} */
 
/* .iframe-container iframe {
   border: 0;
   height: 85%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
} */

.iframe-container {
  position: relative;
  padding-top: 47%;
  height: 0; 
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
  overscroll-behavior: contain;
}
.iframe-container-mobile  {
   position: relative;
   padding-top: 165%;
   height: 0;
}
.iframe-container-mobile iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   overscroll-behavior: contain;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>